// src/components/Header.js
import React from "react";
import { AppBar, Toolbar, IconButton, Button, Typography } from "@mui/material";
import { beyaz, kirmizi, siyah } from "../config/colors";
import Logo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const redirectTo = (url) => {
    window.location.href = url;
  };
  return (
    <AppBar position="static" style={{ background: siyah }}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu">
          <img
            alt="gauarenalogo"
            src={Logo}
            style={{ width: 35, height: 35 }}
          />
        </IconButton>
        <Typography
          style={{
            flexGrow: 1,
            color: beyaz,
            textTransform: "none",
            fontSize: "20px",
            fontFamily: "Manrope, sans-serif",
            letterSpacing: 0,
          }}
        >
          GAU Arena
        </Typography>

        {/* <Button
          color="inherit"
          sx={{ textTransform: "none" }}
          onClick={() => navigate("/serefsiz")}
        >
          Sayfa 1
        </Button>
        <Button
          color="inherit"
          style={{ marginRight: "1%", marginLeft: "1%", textTransform: "none" }}
        >
          Sayfa 2
        </Button> */}
        <Button
          color="inherit"
          style={{
            backgroundColor: kirmizi,
            textTransform: "none",
            borderRadius: "8px",
          }}
          onClick={() =>
            redirectTo(
              "https://gameramobile.s3.eu-west-3.amazonaws.com/gamePics/gamerarena/gauArena.apk"
            )
          }
        >
          Get the App
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
