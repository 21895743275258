// src/components/ComplexSection.js
import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import {
  beyaz,
  kirmizi,
  purple1,
  purple2,
  siyah,
  siyahimsi,
} from "../config/colors";
import Logo from "../assets/sup1.png";
import gau2 from "../assets/gau4.png";
import sup2 from "../assets/2.png";
import sup3 from "../assets/3.png";
import sup4 from "../assets/4.png";
import sup5 from "../assets/5.png";
import sup6 from "../assets/6.png";
import sup7 from "../assets/7.png";

const ComplexSection = () => {
  return (
    <>
      <Box
        sx={{
          background: siyah,
          textAlign: "center",
          padding: "40px 20px",
        }}
      >
        <Box
          sx={{
            bgcolor: siyahimsi,
            padding: "80px 20px",
            borderRadius: "16px",
          }}
        >
          <Typography sx={{ color: beyaz, fontSize: "24px" }}>
            We are <strong style={{ color: kirmizi }}>backed</strong> by a team
            of dedicated
          </Typography>
          <Typography sx={{ color: beyaz, fontSize: "24px" }}>
            collaborators who share our passion
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "center", paddingTop: "3%" }}
          >
            <Grid container>
              {/* <Grid item xs={12} sm={2}>
                <img
                  src={sup4}
                  alt="Şirket Logo 1"
                  style={{ margin: "0 10px", maxWidth: "200px" }}
                />
              </Grid> */}
              <Grid item xs={12} sm={2.4}>
                <img
                  src={sup2}
                  alt="Şirket Logo 2"
                  style={{ margin: "0 10px", maxWidth: "200px" }}
                />
              </Grid>
              <Grid item xs={12} sm={2.4}>
                <img
                  src={sup3}
                  alt="Şirket Logo 3"
                  style={{ margin: "0 10px", maxWidth: "200px" }}
                />
              </Grid>
              <Grid item xs={12} sm={2.4}>
                <img
                  src={sup5}
                  alt="Şirket Logo 3"
                  style={{ margin: "0 10px", maxWidth: "200px" }}
                />
              </Grid>
              <Grid item xs={12} sm={2.4}>
                <img
                  src={sup7}
                  alt="Şirket Logo 3"
                  style={{ margin: "0 10px", maxWidth: "200px" }}
                />
              </Grid>
              <Grid item xs={12} sm={2.4}>
                <img
                  src={sup6}
                  alt="Şirket Logo 3"
                  style={{ margin: "0 10px", maxWidth: "200px" }}
                />
              </Grid>
            </Grid>

            {/* <img
            src={Logo}
            alt="Şirket Logo 4"
            style={{ margin: "0 10px", maxWidth: "100px" }}
          /> */}
          </Box>
        </Box>
      </Box>
      <Box sx={{ bgcolor: siyah, textAlign: "center", padding: "40px 20px" }}>
        {/* Altındaki Büyük Resim */}
        <Grid
          container
          justifyContent="center"
          display="flex"
          sx={{
            marginTop: "80px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            justifyContent="flex-end"
            display="flex"
            alignItems="center"
          >
            <Box
              sx={{
                textAlign: "center",
                marginTop: "20px",
                paddingLeft: "5%",
              }}
            >
              <Box sx={{ maxWidth: "600px", margin: "0 auto" }}>
                <Typography variant="h4" sx={{ color: beyaz }}>
                  Skill-Based Gaming
                </Typography>
                <Typography
                  sx={{
                    color: beyaz,
                    fontSize: "16px",
                    marginTop: "10px",
                    textAlign: "justify",
                    textJustify: "inter-word",
                  }}
                >
                  Step into the GAU Arena, where your gaming skills determine
                  your success. Engage in skill-based duels and tournaments
                  across a variety of gaming genres. From strategic battles to
                  fast-paced action, every game is an opportunity to showcase
                  your talent and earn rewards.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} justifyContent="center" display="flex">
            <img
              src={gau2}
              alt="Büyük Resim"
              style={{
                maxWidth: "70%",
                marginTop: "20px", // İstenilen boşluk
              }}
            />
          </Grid>
        </Grid>

        {/* Başlık ve Yazı */}
      </Box>
    </>
  );
};

export default ComplexSection;
