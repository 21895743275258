import { Box, Grid, List, ListItem, Typography } from "@mui/material";
import React from "react";
import { beyaz, siyah } from "../config/colors";
import bg1 from "../assets/gauplatf.png";
const RightArea = () => {
  return (
    <Box sx={{ backgroundColor: siyah }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          sm={6}
          xs={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src={bg1}
            alt="gauwallet"
            style={{
              maxWidth: "100%",
            }}
          />
        </Grid>

        <Grid
          item
          sm={6}
          xs={12}
          sx={{ display: "flex", justifyContent: "center", px: "9%" }}
        >
          <List>
            <ListItem>
              <Typography variant="h4" sx={{ color: beyaz }}>
                Dynamic Community Engagement
              </Typography>
            </ListItem>
            <ListItem>
              <Typography
                sx={{
                  color: beyaz,
                  fontSize: "16px",
                  marginTop: "10px",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Join a vibrant community of gamers in GAU Arena. Participate in
                community-driven events, share strategies, and make new friends.
                Our platform fosters a dynamic and interactive gaming
                environment, where every player contributes to the lively spirit
                of the arena.
              </Typography>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RightArea;
