import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { beyaz, diger, kirmizi, siyah } from "../config/colors";
import bannerbg from "../assets/gau3.png";
import apple from "../assets/applecomingsoon.png";
import google from "../assets/googlecooo.png";
const Banner = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const redirectTo = (url) => {
    window.location.href = url;
  };
  return (
    <>
      <Box sx={{ backgroundColor: siyah }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ height: matches ? "auto" : "90vh", py: matches ? "7%" : 0 }}
        >
          <Grid
            item
            sm={7}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              pl: !matches && "6%",
            }}
          >
            <List>
              {/* <ListItem sx={{p:0,mb:"3%"}}>
                <Typography sx={{mr:5,color:beyaz,fontSize:"14px"}}>App Store</Typography>
                <Typography sx={{color:beyaz,fontSize:"14px"}}>Google Play</Typography>
              </ListItem> */}
              <ListItem sx={{ p: 0 }}>
                <Typography
                  sx={{
                    fontSize: matches ? "60px" : "100px",
                    color: beyaz,
                    fontWeight: "bold",
                    lineHeight: 1,
                  }}
                >
                  The Ultimate
                </Typography>
              </ListItem>
              <ListItem sx={{ p: 0 }}>
                <Typography
                  sx={{
                    fontSize: matches ? "60px" : "100px",
                    color: kirmizi,
                    fontWeight: "bold",
                    lineHeight: 1.1,
                  }}
                >
                  Web3.0 Gaming App
                </Typography>
              </ListItem>

              {matches ? (
                <>
                  <ListItem sx={{ p: 0, mt: "5%" }}>
                    <img
                      alt="apple"
                      onClick={() =>
                        redirectTo(
                          "https://apps.apple.com/tr/app/gau-arena/id6476374027"
                        )
                      }
                      src={apple}
                      style={{ width: "230px", marginBottom: "10px" }}
                    />
                  </ListItem>
                  <ListItem sx={{ p: 0, mt: "5%" }}>
                    <img
                      alt="google"
                      onClick={() =>
                        redirectTo(
                          "https://gameramobile.s3.eu-west-3.amazonaws.com/gamePics/gamerarena/gauArena.apk"
                        )
                      }
                      src={google}
                      style={{ width: "230px" }}
                    />
                  </ListItem>
                </>
              ) : (
                <ListItem sx={{ p: 0, mt: "5%" }}>
                  <img
                    alt="apple"
                    onClick={() =>
                      redirectTo(
                        "https://apps.apple.com/tr/app/gau-arena/id6476374027"
                      )
                    }
                    src={apple}
                    style={{ width: "230px", marginBottom: "10px" }}
                  />
                  <img
                    alt="google"
                    onClick={() =>
                      redirectTo(
                        "https://gameramobile.s3.eu-west-3.amazonaws.com/gamePics/gamerarena/gauArena.apk"
                      )
                    }
                    src={google}
                    style={{ width: "230px" }}
                  />
                </ListItem>
              )}
            </List>
          </Grid>
          <Grid
            item
            sm={5}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: matches ? "7%" : 0,
            }}
          >
            <img src={bannerbg} style={{ width: "80%", height: "70%" }} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Banner;
