// src/components/MovingElement.js
import React from 'react';
import styled, { keyframes } from 'styled-components';
import logo from '../assets/logo.png'; // Logo'nuzun gerçek dosya yolunu ekleyin
import { Box, List, ListItem, Typography } from '@mui/material';
import { beyaz, siyah } from '../config/colors';
const moveAnimation = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const MovingElementContainer = styled.div`
  display: flex;
  align-items: center;
  animation: ${moveAnimation} 10s linear infinite;
  background-color: #1d222b; // Arka plan rengi
  white-space: nowrap; // Öğeleri yan yana sırala
  width: fullWidth;
`;
const MovingElement = () => {
    return (
        <Box sx={{backgroundColor:siyah, py:"1%"}}>
             <MovingElementContainer>
        <List sx={{backgroundColor:siyah}}>
          <ListItem>
            <Typography sx={{fontSize:"75px", fontStyle:"italic",fontWeight:"bold",color:beyaz}}>
              CHALLENGE
            </Typography>
            <img src={logo} style={{width:"100px",height:"100px", paddingLeft:"2%",paddingRight:"2%"}} />
            <Typography sx={{fontSize:"75px", fontStyle:"italic",fontWeight:"bold",color:beyaz}}>
              EARN
            </Typography>
            <img src={logo} style={{width:"100px",height:"100px", paddingLeft:"2%",paddingRight:"2%"}} />
            <Typography sx={{fontSize:"75px", fontStyle:"italic",fontWeight:"bold",color:beyaz}}>
              CHALLENGE
            </Typography>
            <img src={logo} style={{width:"100px",height:"100px", paddingLeft:"2%",paddingRight:"2%"}} />
            <Typography sx={{fontSize:"75px", fontStyle:"italic",fontWeight:"bold",color:beyaz}}>
              EARN
            </Typography>
            <img src={logo} style={{width:"100px",height:"100px", paddingLeft:"2%",paddingRight:"2%"}} />
            <Typography sx={{fontSize:"75px", fontStyle:"italic",fontWeight:"bold",color:beyaz}}>
              CHALLENGE
            </Typography>
            <img src={logo} style={{width:"100px",height:"100px", paddingLeft:"2%",paddingRight:"2%"}} />
            <Typography sx={{fontSize:"75px", fontStyle:"italic",fontWeight:"bold",color:beyaz}}>
              EARN
            </Typography>
            <img src={logo} style={{width:"100px",height:"100px", paddingLeft:"2%",paddingRight:"2%"}} />
            <Typography sx={{fontSize:"75px", fontStyle:"italic",fontWeight:"bold",color:beyaz}}>
              CHALLENGE
            </Typography>
            <img src={logo} style={{width:"100px",height:"100px", paddingLeft:"2%",paddingRight:"2%"}} />
            <Typography sx={{fontSize:"75px", fontStyle:"italic",fontWeight:"bold",color:beyaz}}>
              EARN
            </Typography>
            <img src={logo} style={{width:"100px",height:"100px", paddingLeft:"2%",paddingRight:"2%"}} />
            <Typography sx={{fontSize:"75px", fontStyle:"italic",fontWeight:"bold",color:beyaz}}>
              CHALLENGE
            </Typography>
            <img src={logo} style={{width:"100px",height:"100px", paddingLeft:"2%",paddingRight:"2%"}} />
            <Typography sx={{fontSize:"75px", fontStyle:"italic",fontWeight:"bold",color:beyaz}}>
              EARN
            </Typography>
            <img src={logo} style={{width:"100px",height:"100px", paddingLeft:"2%",paddingRight:"2%"}} />
              </ListItem>
          {/* Daha fazla ListItem ekleyebilirsiniz */}
        </List>
      </MovingElementContainer>
        </Box>
     
    );
  };

export default MovingElement;