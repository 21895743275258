import { Box, Grid, List, ListItem, Typography } from "@mui/material";
import React from "react";
import { beyaz, siyah } from "../config/colors";
import bg1 from "../assets/gauwallet.png";

const LeftArea = () => {
  return (
    <Box sx={{ backgroundColor: siyah }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          sm={6}
          xs={12}
          sx={{ display: "flex", justifyContent: "center", px: "9%" }}
        >
          <List>
            <ListItem>
              <Typography variant="h4" sx={{ color: beyaz }}>
                Intuitive Crypto Integration
              </Typography>
            </ListItem>
            <ListItem>
              <Typography
                sx={{
                  color: beyaz,
                  fontSize: "16px",
                  marginTop: "10px",
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Experience seamless integration of blockchain technology in
                gaming with GAU Arena. Connect your crypto wallet effortlessly
                and use GAU tokens to enter duels and tournaments. Our platform
                makes blockchain gaming accessible, secure, and thrilling for
                both seasoned crypto enthusiasts and newcomers.
              </Typography>
            </ListItem>
          </List>
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src={bg1}
            alt="gauwallet"
            style={{
              maxWidth: "100%",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LeftArea;
