// Örnek bir bileşen
import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Section from "../components/Section";
import LeftArea from "../components/LeftArea";
import RightArea from "../components/RightArea";
import MovingElement from "../components/MovingElement";
import { useMediaQuery, useTheme } from "@mui/material";

function WelcomeMessage() {
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Header />
      <Banner />
      <Section />
      {!matches && <MovingElement />}
      <RightArea />
      <LeftArea />
      {/* <p>{t("welcome")}</p> */}
    </div>
  );
}

export default WelcomeMessage;
