import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import "./i18n"; // i18n dosyasını içe aktar

////////////////////////// SAYFALAR //////////////////////////

import Main from "./pages/Main";

//////////////////////////

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/serefsiz",
    element: <div>serefsiz</div>,
  },
]);

const App = () => {
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
};

export default App;
